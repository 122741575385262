<template>
    <label for="delay" class="switch_option capsule_btn">
        <h5>Delays</h5>
        <input type="checkbox" id="delay" v-model="hasDelay" :true-value="1" :false-value="0" hidden>
        <div><span></span></div>
    </label>
    <div v-if="hasDelay" class="edit_section mt-2">
        <schedule-tab v-model="delay" ref="schedule-component"></schedule-tab>
        <div class="action_wpr mt-2 px-0">
            <button :disabled="scheduling" type="button" class="btn save_btn" @click="handleSchedule()"><i class="fa fa-spinner fa-spin" v-if="scheduling"></i> {{ scheduling ? 'Scheduling' : 'Schedule' }}</button>
        </div>
    </div>
</template>

<script>
    import Toastr from '@/utils/Toastr'
    import ScheduleTab from '@/components/ScheduleTab'

    import { mapMutations } from 'vuex'

    export default {
        name: 'Schedule',

        data(){
            return{
                hasDelay: false,
                delay: {},
                scheduling: false,
            }
        },

        props: {
            scheduleData: Object,
            title: String,
            closeModal: Function
        },

        components:{
            ScheduleTab,
        },

        methods: {
            ...mapMutations({
                setBulkActionWatcher: 'contactModule/SET_BULK_ACTION_WATCHER',
            }),

            handleSchedule () {
                const vm = this;
                const formData = JSON.parse(JSON.stringify(vm.scheduleData));
                const action = formData.action;
                delete formData.action;

                formData.contact_id = formData.contacts.join(',');

                if (vm.$parent.$parent.validate) {
                    vm.$parent.$parent.validate().then((result) => {
                        if (result.valid) {
                            const schedule = vm.$refs['schedule-component'];
                            vm.scheduling = true;

                            schedule.scheduleDelay(action, formData).then((resp) => {
                                vm.scheduling = false;

                                schedule.refreshDelay();
                                vm.setBulkActionWatcher();

                                if (vm.closeModal) {
                                    vm.closeModal();
                                }

                                Toastr.success(`The ${vm.title} has been scheduled successfully!`);
                            }).catch((err) => {
                                vm.scheduling = false;
                                Toastr.handleServerError(err);
                            });
                        }
                    });
                } else {
                    vm.$parent.validate().then((result) => {
                        if (result.valid) {
                            const schedule = vm.$refs['schedule-component'];
                            vm.scheduling = true;

                            schedule.scheduleDelay(action, formData).then((resp) => {
                                vm.scheduling = false;

                                schedule.refreshDelay();
                                vm.setBulkActionWatcher();

                                if (vm.closeModal) {
                                    vm.closeModal();
                                }

                                Toastr.success(`The ${vm.title} has been scheduled successfully!`);
                            }).catch((err) => {
                                vm.scheduling = false;
                                Toastr.handleServerError(err);
                            });
                        }
                    });
                }
            }
        }
    }
</script>
